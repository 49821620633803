import { Grid, Paper } from "@mui/material"
import { PrimaryText, SubTitle } from "../../../../components/ui/Text"
import React, { useCallback, useEffect, useState } from "react"
import PrivatePage from "../../../../components/features/GlobalPages/Private/PrivatePage"
import { DARK_BLUE, LIGHT_GREY } from "../../../../components/ui/Colors"
import { MembersDataGrid } from "./MembersDataGrid"
import ChartBlock from "../../../../components/features/ChartBlock"
import { PrimaryButton } from "../../../../components/ui/Button"
import { Separator } from "../../../../components/ui/Separator"
import InvitationDialog from "./Invitation"
import { useAppDispatch } from "../../../../redux/hooks"
import { fetchCompany } from "../../../../redux/companies/companiesSlice"

export const AdminPage = () => {
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
    const dispatch = useAppDispatch()

    const handleOpenInvitationDialog = () => {
        setIsDialogOpen(true)
    }

    const _fetchCompany = useCallback(
        async () => await dispatch(fetchCompany()),
        [dispatch]
    )

    useEffect(() => {
        _fetchCompany()
    }, [])

    return (
        <PrivatePage title="Gestion des membres">
            <Grid
                container
                direction="column"
                sx={{ background: LIGHT_GREY }}
                p="1vw"
            >
                <Paper>
                    <Grid container alignItems="center"
                        justifyContent="center" direction="column">
                        <Grid
                            container
                            direction="column"
                            sx={{
                                height: '30vh'
                            }}
                            p="1vw"
                            flexWrap="nowrap"
                        >
                            <Grid container direction="column" width="100%" mt="4vh" ml="4vw" id="0">
                                <SubTitle bold>
                                    Invitez des membres à rejoindre votre espace !
                                </SubTitle>
                                <PrimaryText color={DARK_BLUE}>
                                    Une fois l’invitation acceptée, ils auront accès à
                                    toutes les données de la plateforme.
                                </PrimaryText>
                                <Grid m="4vh">
                                    <PrimaryButton type="submit" onClick={handleOpenInvitationDialog}>
                                        Inviter un collaborateur
                                    </PrimaryButton>
                                    <InvitationDialog isDialogOpen={isDialogOpen} setIsDialogOpen={setIsDialogOpen} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item width="50%">
                            <Separator />
                        </Grid>
                        <Grid height="60vh" item mt="4vh" width="100%">
                            <ChartBlock
                                title={`Vous pouvez gérer ci-dessous les accès des membres de votre organisation : `}
                            >
                                <Grid height={'100%'} >
                                    <MembersDataGrid />
                                </Grid>
                            </ChartBlock>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid >
        </PrivatePage >)
}